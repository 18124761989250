import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, DataTable, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock, PageSubSection, ReferencesBlock} from '../../../components';
import { StaticImage } from 'gatsby-plugin-image';
import hnsccAllGradesSafetyTable from "../../../assets/HNSCC-1L-Combo-safety-all-grades-small-V1.svg"
import hnsccGrades34SafetyTable from "../../../assets/HNSCC-1L-Combo-safety-grades3-4-small-V1.svg"
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-048: Adverse Reactions for First-line Combination Therapy`,
    keywords: `adverse reactions for first-line combination therapy in keynote-048`,
    description: `Learn about the most common adverse reactions in KEYNOTE-048 for first-line combination therapy in certain patients with metastatic or with unresectable, recurrent head and neck squamous cell carcinoma (HNSCC).`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/hnscc-first-line-combination-therapy/","@type":"MedicalWebPage","name":"Most Common Adverse Reactions in KEYNOTE⁠-⁠048","description":"In KEYNOTE⁠-⁠048, when KEYTRUDA was administered in combination with platinum (cisplatin or carboplatin) and FU chemotherapy, KEYTRUDA was discontinued due to adverse reactions in 16% of 276 patients with HNSCC. The most common adverse reactions resulting in permanent discontinuation of KEYTRUDA were pneumonia (2.5%), pneumonitis (1.8%), and septic shock (1.4%). The most common adverse reactions (≥20%) were nausea (51%), fatigue (49%), constipation (37%), vomiting (32%), mucosal inflammation (31%), diarrhea (29%), decreased appetite (29%), stomatitis (26%), and cough (22%)."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/hnscc-first-line-combination-therapy/","@type":"MedicalAudience","audienceType":"health care professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

// image alts - so they do not repeat in mobile & desktop
const hnsccCombo1SafetyAlt = "Common Adverse Reactions in KEYNOTE-048"
const hnsccCombo2SafetyAlt = "Safety Profile of KEYTRUDA® (pembrolizumab) Monotherapy vs EXTREME (Grades 3–4) in KEYNOTE-048"

//from /src/util/data/indications.js
const indicationId = 9;
const jobCode = jobCodes[9].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Additional Safety Data",
            items: [
                { text: 'HNSCC 1L Monotherapy', url: '/safety/adverse-reactions/hnscc-first-line-monotherapy/' },
                ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management ', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
                ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
        {label: 'Incidence of Selected Immune-Mediated Adverse Reactions'},
        {label: 'Management of Selected Immune-Mediated Adverse Reactions'},
        {label: 'Most Common Adverse Reactions in KEYNOTE&#8288;-&#8288;048'}
]

const commonTableAllGradesFootnotes = [
    {
        label: `c.`,
        text: `Graded per NCI CTCAE v4.0`,
    },
    {
        label: `d.`,
        text: `Includes fatigue, asthenia`,
    },
    {
        label: `e.`,
        text: `Includes diarrhea, colitis, hemorrhagic diarrhea, microscopic colitis`,
    },
    {
        label: `f.`,
        text: `Includes dermatitis, dermatitis acneiform, dermatitis allergic, dermatitis bullous, dermatitis contact, dermatitis exfoliative, drug eruption, 	erythema, erythema multiforme, rash, erythematous rash, generalized rash, macular rash, maculo-papular rash, pruritic rash, seborrheic 	dermatitis`,
    },
    {
        label: `g.`,
        text: `Includes cough, productive cough`,
    },
    {
        label: `h.`,
        text: `Includes dyspnea, exertional dyspnea`,
    },
    {
        label: `i.`,
        text: `Includes pneumonia, atypical pneumonia, bacterial pneumonia, staphylococcal pneumonia, aspiration pneumonia, lower respiratory tract infection, lung infection, lung infection pseudomonal`,
    },
    {
        label: `j.`,
        text: `Includes peripheral sensory neuropathy, peripheral neuropathy, hypoesthesia, dysesthesia.`,
    },
    {
        label: `k.`,
        text: `Includes back pain, musculoskeletal chest pain, musculoskeletal pain, myalgia`,
    },
];

const commonTableGrades34Footnotes = [
    {
        label: `l.`,
        text: `Graded per NCI CTCAE v4.0`,
    },
    {
        label: `m.`,
        text: `Includes fatigue, asthenia`,
    },
    {
        label: `n.`,
        text: `Includes diarrhea, colitis, hemorrhagic diarrhea, microscopic colitis`,
    },
    {
        label: `o.`,
        text: `Includes dermatitis, dermatitis acneiform, dermatitis allergic, dermatitis bullous, dermatitis contact, dermatitis exfoliative, drug eruption, erythema, erythema multiforme, rash, erythematous rash, generalized rash, macular rash, maculo-papular rash, pruritic rash, seborrheic 	dermatitis`,
    },
    {
        label: `p.`,
        text: `Includes cough, productive cough`,
    },
    {
        label: `q.`,
        text: `Includes dyspnea, exertional dyspnea`,
    },
    {
        label: `r.`,
        text: `Includes pneumonia, atypical pneumonia, bacterial pneumonia, staphylococcal pneumonia, aspiration pneumonia, lower respiratory tract infection, lung infection, lung infection pseudomonal`,
    },
    {
        label: `s.`,
        text: `Includes peripheral sensory neuropathy, peripheral neuropathy, hypoesthesia, dysesthesia`,
    },
    {
        label: `t.`,
        text: `Includes back pain, musculoskeletal chest pain, musculoskeletal pain, myalgia`,
    }
]

const commonTableAllGradesDefinitions = `<p>
plat/FU = platinum + fluorouracil; EXTREME = cetuximab + platinum + fluorouracil.</p>`

const pageReferences = [
    {
        label: '1.',
        text: 'Burtness B, Harrington KJ, Greil R, et al; for the KEYNOTE&#8288;-&#8288;048 investigators. Pembrolizumab alone or with chemotherapy versus cetuximab with chemotherapy for recurrent or metastatic squamous cell carcinoma of the head and neck (KEYNOTE&#8288;-&#8288;048): a randomised, open-label, phase 3 study. <i>Lancet.</i> 2019;394(10212):1915–1928.',
    }
]

const Page = ({ location }) => {
    
    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                   </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        <PageSection title={anchorLinkModelData[2].label}>
                        <PageSubSection title="Median follow-up times: 13.0 months for KEYTRUDA + plat/FU and 10.7 months for EXTREME.<sup>1</sup>"></PageSubSection>
                            <TextBlock>
                                <p>KEYTRUDA was discontinued for adverse reactions in 16% of patients in the combination arm. The most common adverse reactions resulting in permanent discontinuation of KEYTRUDA were pneumonia (2.5%), pneumonitis (1.8%), and septic shock (1.4%).</p>
                            </TextBlock>

                            <PageSubSection title="Safety Profile of KEYTRUDA + plat/FU vs EXTREME (All Grades)">
                                <DataTable 
                                    title="Adverse Reactions (All Grades) Occurring in ≥10% of Patients Receiving KEYTRUDA + plat/FU in KEYNOTE-048 (Final Analysis)"
                                    mobileImage={() => <img src={hnsccAllGradesSafetyTable} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={hnsccCombo1SafetyAlt}/>}
                                    desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/hnscc-1-l-combo-safety-all-grades-large-v-1.png" alt={hnsccCombo1SafetyAlt}/>}    
                                    footnotes={commonTableAllGradesFootnotes} 
                                    definitions={commonTableAllGradesDefinitions}
                                />
                            </PageSubSection>
                        
                            <PageSubSection title="Safety Profile of KEYTRUDA + plat/FU vs EXTREME (Grades 3–4)">
                                <DataTable 
                                    title="Grade 3–4 Adverse Reactions in the Population of Patients With Any Grade Adverse Reaction Occurring in ≥10% of Patients Receiving KEYTRUDA + plat/FU in KEYNOTE-048 (Final Analysis)"
                                    mobileImage={() => <img src={hnsccGrades34SafetyTable} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={hnsccCombo2SafetyAlt}/>}
                                    desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/HNSCC-combo-safety-2.png" alt={hnsccCombo2SafetyAlt}/>}    
                                    footnotes={commonTableGrades34Footnotes} 
                                />
                            </PageSubSection>

                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}

                <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
